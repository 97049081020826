import React, { useState, useEffect } from "react";
import "../privacy-policy/PrivacyPolicy.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HeaderMenu from "../../components/layout/HeaderMenu";
import { useToast } from "../../context/ToastContext";

const PrivacyPolicy = () => {
  const { labels, policyTerms, general } = useToast();
  const [loading, setLoading] = useState(false);
  const privacyPolicy = !!policyTerms?.privacyPolicy
    ? JSON.parse(policyTerms.privacyPolicy)
    : {};

  useEffect(() => {
    if (policyTerms === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [policyTerms]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {general.GEN0009} | {labels.LBL0075}
        </title>
      </Helmet>

      <section className="tc-pp-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
      </section>

      {loading ? (
        <section className="payment-loading">
          <div className="loading-block">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
            <p>{labels.LBL0069}</p>
          </div>
        </section>
      ) : (
        <section className="about-blocks-section tc-block">
          <div className="privacy-policy-section terms-and-conditions-section">
            <div className="mb-5">
              <h1 className="main-heading">{privacyPolicy.title}</h1>
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: privacyPolicy.description }}
            />
          </div>
        </section>
      )}
    </HelmetProvider>
  );
};

export default PrivacyPolicy;
