import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import {
  isIOS,
  osVersion,
  isMobile,
} from 'react-device-detect';
import EdiText from "react-editext";

import * as PR from "../../prime-modules";
import { esimsV2, esimsICCID, updateTag } from "../../services/api";
import { esimIccidActions } from "../../store/esim";
import {
  formatBundleExpiration,
  modifyDateFormat,
  checkDataSize,
  getDayLabel,
  getBundleDescription,
} from "../../utils/reuse";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import EsimActivationDialog from "../../shared/components/EsimActivationDialog";
import { useToast } from "../../context/ToastContext";

const MyEsims = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast, account, labels } = useToast();
  const userData = useSelector((state) => state.auth.sessionData);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { sessionid: userData.sessionId, afid: affiliateTrackingData };
  }, [userData.sessionId, affiliateTrackingData]);

  const [loading, setLoading] = useState(false);
  const [qrDisplay, setQRDisplay] = useState(false);
  const [qrData, setQRData] = useState(false);
  const [activeeSIM, setActiveeSIM] = useState(false)
  const [activeBundleDialog, setActiveBundleDialog] = useState(false);
  const [activeBundlesData, selectedActiveBundlesData] = useState([]);
  const [esimStatus, setEsimStatus] = useState(null);
  const isLoading = useSelector(state => state.esim.iseSIMsLoading);
  const [eSIMs, seteSIMs] = useState([])


  const esimStatusList = [
    { label: account.ACT0068, value: "Installed" },
    { label: account.ACT0069, value: "Enabled" },
    { label: account.ACT0070, value: "Released" },
    { label: account.ACT0071, value: "Downloaded" },
    { label: account.ACT0072, value: "Disabled" },
    { label: account.ACT0073, value: "Unavailable" },
    { label: account.ACT0074, value: "Unknown" },
  ];

  const onEsimStatusChange = (value) => {
    setEsimStatus(value);
  };

  // Get list of esims
  const getEsimsICCID = useCallback((iccid) => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        const data = response.data;
        const activeBundles = data?.activeBundlesData?.filter(bundle => bundle.state === "active");
        data.activeBundles = activeBundles?.length > 0 ? activeBundles : [];
        const responseData = [data]

        seteSIMs((prevState) => {
          const filteredData = responseData.filter(
              (newEsim) => !prevState.some((existingEsim) => existingEsim.iccid === newEsim.iccid)
          );
          const updatedState = [...prevState, ...filteredData];
          return updatedState.sort((a, b) => new Date(b.createdTs) - new Date(a.createdTs));
        });
      } else {
        const error = response.error;
        showToast(error.severity, error.errorMsg);
      }
    };
      esimsICCID(iccid, headers, dispatch, getResponse);
  }, [headers, dispatch, showToast]);
  
  const getEsims = useCallback(() => {
    dispatch(esimIccidActions.storeLoading(true));
    const getResponse = (response) => {
      if (response?.result === "SUCCESS") {
        const esimsData = response.data || [];
        esimsData.forEach((iccidItem) => {
          getEsimsICCID(iccidItem.iccid);
        });
      dispatch(esimIccidActions.storeLoading(false));
      } else {
        dispatch(esimIccidActions.storeLoading(false));
        const error = response.error;
        showToast(error.severity, error.errorMsg);      
      }
    };
      esimsV2(headers, dispatch, getResponse);
  }, [headers, dispatch, getEsimsICCID, showToast]);  

  useEffect(() => {
    getEsims();
}, [ getEsims]);

  const tagComponent = (esim) => {
    return (
      <EdiText
        name="tag"
        value={esim.tag}
        type="text"
        placeholder={account.ACT0088}
        onSave={(e) => tagHandleSave(esim, e)}
        validation={validateTagValue}
        validationMessage={account.ACT0089}
        onCancel={(e) => e}
      />
    );
  };

  const tagHandleSave = (rowData, value) => {
    if (validateTagValue(value) && rowData.tag !== value) {
      const iccid = rowData.iccid;
      const request = {
        userId: rowData.userId,
        iccid: iccid,
        tag: value,
      };
      dispatch(esimIccidActions.storeLoading(true));
      const tagResponse = (response) => {
        if (response.result === "SUCCESS") {
          dispatch(esimIccidActions.storeLoading(false));
          showToast("success", "UPDTAG-03");
        } else if (response.result === "FAILED" && response.error) {
          setLoading(false);
          const error = response.error;
          showToast(error.severity, error.errorMsg);
        }
        getEsims();
      };
      updateTag(request, headers, dispatch, tagResponse);
    }
  };

  const validateTagValue = (text) => {
    if (text.length === 0) {
      return true;
    } else if (text.length > 0) {
      return text.length >= 3 && text.length <= 10;
    }
  };

  const displayQRCode = (qrcode) => {
    const qrCode = "data:image/png;base64," + qrcode;
    setQRData(qrCode);
    setQRDisplay(true);
  };

  const downloadQRCode = (qrcode) => {
    let downloadLink = document.createElement("a");
    downloadLink.href = qrcode;
    downloadLink.download = "QR_Code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const viewEsimsHandler = (esim) => {
    props.setUserId(esim.userId)
    const esimIccid = {
      esimIccid: esim.iccid,
      tag: esim.tag,
      providerCode: esim?.providerCode
    };
    dispatch(esimIccidActions.setEsim(esimIccid));
    props.setDisplayScreen(1);
  };

  const topupEsimsHandler = (esim) => {
    const addeBundleData = {
      esimIccid: esim.iccid,
      tag: esim.tag,
    };
    dispatch(esimIccidActions.setEsim(addeBundleData));
    props.setDisplayScreen(2);
  };

  const renderProgressBarTemplate = (bundle) => {
    const bundleEndTime = bundle.bundleEndTime;
    const bundleStartTime = bundle.bundleStartTime;
    const date = new Date(bundle.bundleStartTime).getFullYear();
    const remainingDays = formatBundleExpiration(bundleStartTime, bundleEndTime, labels).remainingDays;
    return (
      <>
        <p>{formatBundleExpiration(bundleStartTime, bundleEndTime, labels).output}
        {remainingDays + " " +  getDayLabel(remainingDays, labels)}
        </p>
        <PR.ProgressBar
          value={
            formatBundleExpiration(bundleStartTime, bundleEndTime, labels)
              .usedDataPercentage
          }
        ></PR.ProgressBar>
        {date !== 1 && <p className="text-right">
          {account.ACT0075} <span>{modifyDateFormat(bundleEndTime)}</span>
        </p>}
      </>
    );
  };

  const displayMultipleBundles = (esim) => {
    setActiveBundleDialog(true);
    selectedActiveBundlesData(esim.activeBundles);
  };

  const addDataHandler = () => {
    navigate("/")
    dispatch(scrollToBundlesActions.setScrollToBundles(true));
  }

  return (
    <>
      {loading ? (
        <div className="loading-div">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <div className="esims-section">
          {isLoading ? (
            <div className="flex align-items-center justify-content-center data-loading-block">
              <i className="pi pi-spin pi-spinner"></i>
              <p>{account?.ACT0121 || "Fetching Data"}</p>
            </div>
          ) : (
            ""
          )}
          <div className="card status-dropdown">
            <PR.Dropdown
              filter
              value={esimStatus}
              resetFilterOnHide
              options={esimStatusList}
              onChange={(e) => onEsimStatusChange(e.value)}
              optionLabel="label"
              optionValue="value"
              placeholder={account.ACT0079}
              showClear
            />
          </div>
          <h3>{account.ACT0078}</h3>
          <div className="grid">
            {eSIMs &&
              eSIMs
                ?.filter((e) => (esimStatus ? e.status === esimStatus : true))
                .map((esim) => {
                  return (
                    <div className="col-12 lg:col-6 md:col-6" key={esim.iccid}>
                      <div className="esim-box">
                        <ul className="flex align-items-center justify-content-between user-edit-ul">
                          <li>
                            <p className="id_p">{esim.iccid}</p>
                          </li>
                          <li>
                            <span className={"status-box " + esim.status}>
                              {esim.status}
                            </span>
                          </li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between user-edit-ul">
                          <li>
                            <span>{tagComponent(esim)}</span>
                          </li>
                        </ul>

                        {esim.status !== "Released" && (
                          <div className="text-center active-esim-button">
                            <PR.Button
                              label={
                                esim.count +
                                " " +
                                (esim.count > 1
                                  ? account.ACT0076
                                  : account.ACT0077)
                              }
                              className="p-button-primary multiple-bundles-btn"
                              onClick={() => displayMultipleBundles(esim)}
                              disabled={esim.count === 0}
                            />
                          </div>
                        )}
                        {esim.smdpAddress && esim.matchingId && (
                          <EsimActivationDialog
                            smdpAddress={esim.smdpAddress}
                            matchingId={esim.matchingId}
                            activeeSIM={activeeSIM}
                            setActiveeSIM={setActiveeSIM}
                          />
                        )}

                        {esim.status === "Released" && (
                          <div className="esim-not-installed">
                            {esim.qrCodeImage && (
                              <i
                                className="pi pi-qrcode"
                                onClick={() => displayQRCode(esim.qrCodeImage)}
                              ></i>
                            )}
                            <p>{account.ACT0090}</p>
                            <p className="font-weight-light">
                              {account.ACT0091}
                            </p>
                          </div>
                        )}

                        <ul className="buttons-ul flex align-items-center justify-content-between">
                          <li>
                            <PR.Button
                              label={account.ACT0064}
                              icon="pi pi-qrcode"
                              className="choose-plans-button p-button-rounded"
                              onClick={() => viewEsimsHandler(esim)}
                            />
                          </li>
                          {esim.status === "Released" &&
                          isMobile &&
                          isIOS &&
                          osVersion >= "17.4" ? (
                            <li>
                              <PR.Button
                                onClick={() => setActiveeSIM(true)}
                                label={account.ACT0092}
                                icon="pi pi-check-circle"
                                className="choose-plans-button p-button-rounded"
                              />
                            </li>
                          ) : (
                            <li>
                              <PR.Button
                                label={account.ACT0030}
                                icon="pi pi-credit-card"
                                className="choose-plans-button p-button-rounded"
                                onClick={() => topupEsimsHandler(esim)}
                              />
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  );
                })}
            {!loading && (
              <div className="col-12 lg:col-6 md:col-6">
                <div className="esim-box not-installed-esim-box">
                  <div>
                    <i
                      className="pi pi-plus-circle"
                      onClick={addDataHandler}
                    ></i>
                    <p onClick={addDataHandler}>{account.ACT0093}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <PR.Dialog
        header={account.ACT0080}
        className="multiple-bundles-dialog"
        draggable={false}
        blockScroll={true}
        resizable={false}
        style={{ width: "40%" }}
        visible={activeBundleDialog}
        onHide={() => setActiveBundleDialog(false)}
      >
        {activeBundlesData.map((bundle, index) => {
          let percentage = bundle.totalData
            ? ((bundle.totalData - bundle.remainingData) / bundle.totalData) *
              100
            : 0;
          const bundleStartYear = new Date(
            bundle?.bundleStartTime
          ).getFullYear();
          const bundleEndYear = new Date(bundle?.bundleEndTime).getFullYear();
          return (
            <div className="active-bundles-box" key={index}>
              <ul className="user-edit-ul flex align-items-center justify-content-between">
                <li>
                  <b>{account.ACT0081}</b>
                </li>
                <li>{getBundleDescription(bundle)}</li>
              </ul>
              <>
                {bundle?.isUnlimited === true ? (
                  <h4>
                    {labels.LBL0167} {account.ACT0111}
                  </h4>
                ) : (
                  <div className="card progress-bar">
                    <p>
                      <span>
                        {checkDataSize(bundle.totalData - bundle.remainingData)}
                      </span>{" "}
                      {account.ACT0082} {checkDataSize(bundle.totalData)}{" "}
                      {account.ACT0083}
                    </p>
                    <PR.ProgressBar value={percentage}></PR.ProgressBar>
                    <p className="text-right">
                      <span>{checkDataSize(bundle.remainingData)}</span>{" "}
                      {account.ACT0084}
                    </p>
                  </div>
                )}
                {bundleStartYear !== 1 && bundleEndYear !== 1 && (
                  <div className="card progress-bar">
                    {renderProgressBarTemplate(bundle)}
                  </div>
                )}
              </>
            </div>
          );
        })}
      </PR.Dialog>

      <PR.Dialog
        visible={qrDisplay}
        onHide={() => setQRDisplay(false)}
        header={account.ACT0085}
        draggable={false}
        blockScroll={true}
        resizable={false}
        className="download-qr-dialog"
      >
        <div className="paymentsummary-qr-section">
          <img src={qrData} id="qrCodeEl" alt="qrcode" className="qr-code" />
          <p>{account.ACT0086}</p>
          <PR.Button
            label={account.ACT0087}
            className="p-button-primary download-button"
            onClick={() => downloadQRCode(qrData)}
          />
        </div>
      </PR.Dialog>
    </>
  );
};

export default MyEsims;
