export const globalConfig = {
    domainName: 'eSimLinks',
    dataSize: 'GB',
    defaultCurrency: '$',
    libraries: ['places'],
    allowPhoneNumberLength: 16,
    paymentDefaultCurrency: 'usd',
    paymentDefaultCountry: 'US',
    paymentDefaultLabel: 'eSIM Purchase',
    domainUrl:"https://esimlinks.com",
    allowNameLength: 30,
    toastDisplayTime: 5000,
    toastDisplayErrorTime: 10000000,
    deviceInfo: "website",
    isEsimCompatible: false,
    catalogDataAmountperGB: 1000,
    notificationCount:20
}
export const globalConfigData = globalConfig;

export const envConfig = process.env

export const responsiveOptions = [
    {
        breakpoint: "1199px",
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: "991px",
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: "767px",
        numVisible: 1,
        numScroll: 1
    }
];