import { PaymentElement, useCustomCheckout } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as PR from "../../../prime-modules/index";
import { useToast } from "../../../context/ToastContext";
import { renderPrice } from "../../../utils/reuse";

const CardPayment = ({ setApplyPromoCode, applyPromoCode, userInfo }) => {
  const { showToast, labels } = useToast();

  const cartData = useSelector((state) => state.cart.catalogCart);
  const { confirm } = useCustomCheckout();

  const checkout = useCustomCheckout();
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const totalPayAmount = cartData?.discountedPrice
    ? cartData.discountedPrice
    : cartData.price;
  const paymentLabel = labels.LBL0103 + " " + renderPrice(totalPayAmount);

  useEffect(() => {
    if (userInfo?.email && !checkout?.email) {
      checkout.updateEmail(userInfo?.email);
    }
  }, [checkout, userInfo?.email]);

  const payNowHandler = async () => {
    setPaymentProcessing(true);
    confirm().then((result) => {
      setPaymentProcessing(false);
      if (result.error) {
        showToast("error", result?.error?.message);
      }
    });
  };

  useEffect(() => {
    if (applyPromoCode && applyPromoCode !== "remove") {
      checkout.applyPromotionCode(
        applyPromoCode === true ? "" : applyPromoCode
      );
      setApplyPromoCode("");
    } else if (applyPromoCode === "remove") {
      checkout.removePromotionCode();
      setApplyPromoCode("");
    }
  }, [applyPromoCode, checkout, setApplyPromoCode]);

  return (
    <div className="card-section">
      <PaymentElement
        options={{
          layout: "accordion",
        }}
      />
      <PR.Button
        type="button"
        onClick={payNowHandler}
        label={paymentLabel}
        className="continue-button"
        disabled={paymentProcessing}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default CardPayment;
