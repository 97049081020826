import React, { useState, useEffect } from "react";
import "../terms-and-conditions/TermsAndConditions.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HeaderMenu from "../../components/layout/HeaderMenu";
import { Link } from "react-router-dom";
import { useToast } from "../../context/ToastContext";

const TermsAndConditions = () => {
  const { labels, general, policyTerms } = useToast();
  const [loading, setLoading] = useState(false);
  const termsAndConditions = !!policyTerms?.termsAndConditions
    ? JSON.parse(policyTerms.termsAndConditions)
    : {};

  useEffect(() => {
    if (policyTerms === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [policyTerms]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {general.GEN0009} | {labels.LBL0178}
        </title>
      </Helmet>

      <section className="tc-pp-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
      </section>
      {loading ? (
        <section className="payment-loading">
          <div className="loading-block">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
            <p>{labels.LBL0069}</p>
          </div>
        </section>
      ) : (
        <section className="about-blocks-section tc-block">
          <div className="terms-and-conditions-section">
            <div className="mb-5">
              <h1 className="main-heading">{termsAndConditions.title}</h1>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: termsAndConditions.description,
              }}
            />
          </div>
        </section>
      )}
    </HelmetProvider>
  );
};

export default TermsAndConditions;
