import axios from "axios";
import { authActions } from '../store/auth';
import { sitemaintenanceInfoActions } from "../store/sitemaintenance";
import { esimIccidActions } from '../store/esim';

const baseUrl = '/v1/';
const baseUrl2 = '/v2/'
const errorHandler = (err, dispatch) => {
    let errorMessage = {};
    switch (err.response && err.response.status) {
        case 504:
            errorMessage.severity = 'error';
            errorMessage.summary = 'GNRMSG-504';
            errorMessage.errorMsg = err.response.data?.errorMsg;
            break;
        default:
            errorMessage.severity = 'error';
            errorMessage.summary = err.response?.statusText ? err.response?.statusText : "Error";
            errorMessage.errorMsg = err.response?.data?.errorMsg ? err.response.data?.errorMsg : "GNRMSG-07";
            if(err.response && err.response.status === 401) {
                dispatch(authActions.onLogout());
                dispatch(esimIccidActions.reseteSIMS());
            } 
            break;
    }
    return errorMessage;
}

const siteUnderMaintenance = (status, dispatch) => {
    dispatch(sitemaintenanceInfoActions.setSitemaintenanceInfo(status));
}

export const login = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'login', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            const sessionId = response.headers['sessionid'];
            if (response && sessionId) {
                response.data.sessionId = sessionId;
                callback({ result: 'SUCCESS', data: response.data });
            } else {
                callback({ result: 'FAILED', data: 'Failed' });
            }
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const signup = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'signup', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data })
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            let errorMessage = errorHandler(error, dispatch);
            errorMessage.status = error?.response?.status
            callback({ result: 'FAILED', error: errorMessage })
        })
}

export const verifyotp = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'verifyemail', data, { headers: headers })
        .then(response => {
            const sessionId = response.headers['sessionid'];
            if (response && sessionId) {
                siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
                response.data.sessionId = sessionId;
                callback({ result: 'SUCCESS', data: response.data })
            } else {
                callback({ result: 'FAILED', data: 'Failed' });
            }

        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            let errorMessage = errorHandler(error, dispatch);
            errorMessage.status = error?.response?.status
            callback({ result: 'FAILED', error: errorMessage })
        })
}
export const reSendOtpApi = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'reverifyemail', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data })
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            let errorMessage = errorHandler(error, dispatch);
            errorMessage.status = error?.response?.status
            callback({ result: 'FAILED', error: errorMessage })
        })
}

export const googleLogin = (code, headers, dispatch, callback) => {
    axios.get(baseUrl + 'googlelogin?code=' + code, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            const sessionId = response.headers['sessionid'];
            if (response && sessionId) {
                response.data.sessionId = sessionId;
                callback({ result: 'SUCCESS', data: response.data });
            } else {
                callback({ result: 'FAILED', data: 'Failed' });
            }
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const appleLogin = (requestObj, headers, dispatch, callback) => {
    axios.post(baseUrl + 'appleloginweb', requestObj, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            const sessionId = response.headers['sessionid'];
            if (response && sessionId) {
                response.data.sessionId = sessionId;
                callback({ result: 'SUCCESS', data: response.data });
            } else {
                callback({ result: 'FAILED', data: 'Failed' });
            }
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const getBundleDetails = (data, headers, dispatch, callback) => {
    axios.get(baseUrl + 'catalogs/' + data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const getCatalogsList = (data, headers, dispatch, callback) => {
    axios.get(baseUrl + 'catalogs?' + data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const getCountriesList = (url, headers, dispatch, callback) => {
    axios.get(baseUrl + url, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data, ipcountry: response.headers['x-cf-ipcountry'] });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const stripePayment = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'stripepayment', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            window.location.pathname !== "/my-account" && dispatch(esimIccidActions.deleteEsim());
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error?.response?.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const discountPayment = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'freeorder', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            window.location.pathname !== "/my-account" && dispatch(esimIccidActions.deleteEsim());
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error?.response?.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const paypalPayment = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'paypalpayment', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response?.headers['siteundermaintenance'], dispatch);
            window.location.pathname !== "/my-account" && dispatch(esimIccidActions.deleteEsim());
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response?.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const contact = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'contact', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const validatetag = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'validatetag', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const forgotPassword = (url, headers, dispatch, callback) => {
    axios.get(baseUrl + url, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const resetPassword = (request, headers, dispatch, callback) => {
    axios.put(baseUrl + 'resetpassword', request, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const verifyemail = (request, apiUrl, headers, dispatch, callback) => {
    axios.post(baseUrl + apiUrl, request, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const updateemail = (request, headers, dispatch, callback) => {
    axios.post(baseUrl + 'updateemail', request, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const notifyemail = (request, headers, dispatch, callback) => {
    axios.post(baseUrl + 'notifyemail', request, { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const getUserOrderDetails = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'orders', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const updateUserProfile = (data, headers, dispatch, callback) => {
    axios.put(baseUrl + 'profile', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const changeUserPassword = (data, headers, dispatch, callback) => {
    axios.put(baseUrl + 'changepassword', data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const esimsV2 = ( headers, dispatch, callback) => {
    axios.get(baseUrl2 + `esims`, {headers : headers})
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    }).catch(error => {
        const errorMessage = errorHandler(error,dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
  }
  
  export const esimsICCID = ( iccid, headers, dispatch, callback) => {
    axios.get(baseUrl + `esims/${iccid}` , {headers : headers})
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    }).catch(error => {
        const errorMessage = errorHandler(error,dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
  }

export const getEsimBundles = (request, headers, dispatch, callback) => {
    axios.get(baseUrl + 'bundles/' + request.userId + '/' + request.iccid, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const updateTag = (request, headers, dispatch, callback) => {
    axios.put(baseUrl + 'edittag', request, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const validatePromoCodeAPI = (requestObj, headers, dispatch, callback) => {
    axios.post(baseUrl + 'validatepromocode', requestObj, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const getNotifications = (request, headers, dispatch, callback) => {
    axios.get(baseUrl + "notifications?" + request, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const updateNotificationStatus = (request, headers, dispatch, callback) => {
    axios.put(baseUrl + 'updatenotificationstatus', request, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const getCountryInfo = (request, dispatch, callback) => {
    axios.get(baseUrl + "countryinfo?" + request)
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getFreeeEsimDetails = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'ynertiacheckout',data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getAssetsData = (lang,screen,callback) => {
    axios.get(baseUrl + `assets?language=${lang}&screen=${screen}`)    
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    })
    .catch(error => {
        const errorMessage = errorHandler(error);
        callback({ result: 'FAILED', error: errorMessage });
    })
}
export const redeemVoucherAPI = (request, headers, dispatch, callback) => {
    axios.post(baseUrl + "redeemvoucher", request, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const getTopUpPrepaidBundle = (data, headers, dispatch, callback) => {
    axios.get(baseUrl + 'topupprepaidbundle?bundle=' + data, { headers: headers })
        .then(response => {
            siteUnderMaintenance(response.headers['siteundermaintenance'], dispatch);
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            siteUnderMaintenance(error.response.headers['siteundermaintenance'], dispatch);
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getCheckoutSessionAPI = (data, headers, dispatch, callback) => {
    axios
      .post(baseUrl + "stripe/session", data, { headers })
      .then((response) => {
        callback({ result: "SUCCESS", data: response.data });
      })
      .catch((error) => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: "FAILED", error: errorMessage });
      });
  };
  
  export const getStripePaymentDetailsAPI = (data, headers, dispatch, callback) => {
    axios
      .get(baseUrl + "stripe/session/checkout" + data, {headers})
      .then((response) => {
        callback({ result: "SUCCESS", data: response.data });
      })
      .catch((error) => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: "FAILED", error: errorMessage });
      });
  };