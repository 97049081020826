import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { envConfig } from "../../constants";

const Paypal = () => {
    const initialOptions = {
        "client-id": envConfig.REACT_APP_PAYPAL_CLIENT_ID
    };

    const _createOrder = (data, actions) => {
        const dynamicData = window.dynamicData;  
        return actions.order.create(dynamicData); 
    }

    const _onApprove = async (data, actions) => {
        let order = await actions.order.capture();
        window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(JSON.stringify(order));    
        return order;
    }

    const _onError = (err) => {
        let errObj = {
            err: err,
            status: "FAILED",
        };
        window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(JSON.stringify(errObj));
    }

    return (
        <div style={{padding: '1.5rem'}}>
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons style={{layout: 'horizontal'}}
                createOrder={(data, actions) => _createOrder(data, actions)}
                onApprove={(data, actions) => _onApprove(data, actions)}
                onCancel={() => _onError("Canceled")}
                onError={(err) => _onError(err)}
            />
        </PayPalScriptProvider>
        </div>
    );
}

export default Paypal;