import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { useDispatch, useSelector } from 'react-redux'
import { CustomCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { debitCardIcon, esimIcon, paypalIcon, poweredByStrpeIcon } from "../../assets/images";
import PaypalPayment from "./Paypal";
import { envConfig } from "../../constants";
import { v4 as uuidv4 } from 'uuid';
import { cartFilterActions } from "../../store/cart";
import { discountPayment, getCheckoutSessionAPI, validatePromoCodeAPI } from "../../services/api";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { handleFirstNameChange, renderPrice, setDataAmount } from "../../utils/reuse";
import DeviceCompatibilty from "./DeviceCompatibilty";
import { getPhoneObj } from "../../shared/methods/GetAllCountries";
import { useNavigate } from "react-router-dom";
import { paymentActions } from "../../store/payment";
import { useToast } from "../../context/ToastContext";
import { unlimitedPlanUsageAsterisk } from "../helper";
import CardPayment from "./stripe/CardPayment";
import { elementsOptions } from "./elementsOptions";

const stripePublicKeyV2 = loadStripe(envConfig.REACT_APP_STRIPE_PUBLIC_KEY,  {
    betas: ['custom_checkout_beta_4'],
  });

const MakePayment = (props) => {
    const dispatch = useDispatch();
    const { showToast, showMessage, validations, globalConfig, labels } = useToast();
    const navigate = useNavigate();
    // const defaultPaymentMethod = envConfig.REACT_APP_DEFAULT_PAYMENT_METHOD;
    const orderAmount = useSelector((state) => state.cart.catalogCartAmount);
    const cartData = useSelector((state) => state.cart.catalogCart);
    const isAuth = useSelector(state => state.auth.isLoggedIn);
    const userData = useSelector(state => state.auth.sessionData);
    const randomId = useSelector((state) => state.cart.randomId);
    const iccid = useSelector(state => state.esim.esimIccid);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo ? state.affiliateTracking.affiliateTrackingInfo : affiliatePath?.afid);
    const headers = useMemo(() => {
        return { sessionid: userData.sessionId ? userData.sessionId : "", afid: affiliateTrackingData };
    }, [userData.sessionId, affiliateTrackingData]);
    const [paymentType, setPaymentType] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [paymentResponse, setPaymentResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeError, setPromoCodeError] = useState('');
    const [userFormVisible, setUserFormVisible] = useState(false);
    const [compatibilityCheck, setCompatibilityCheck] = useState(false);
    const [paymentVisible, setPaymentVisible] = useState(false);
    const payVisible = paymentType === "PAYPAL" || paymentType === "GPAY" || paymentType === "APPLEPAY"
    const totalOrderAmount = cartData?.promoCodePrice ? cartData.discountedPrice : cartData.price;
    const [initialCartData, setInitialCartData] = useState({})
    const [clientSecretLoading, setClientSecretLoading] = useState(false);
    const [sessionData, setSessionData] = useState({});
    const [affiliatePromoCodeCall, setAffiliatePromoCodeCall] = useState(false);
    const [applyPromoCode, setApplyPromoCode] = useState("");
    const [userInfo, setUserInfo] = useState(userData || {});

    const paymentProps = (paymentRes, loadingStatus) => {
        setPaymentResponse(paymentRes);
            paymentRes !== '' && navigateSuccessScreen(paymentRes);
        setLoading(loadingStatus);
    };

    const v2PayHandler = useCallback(
        (values, data) => {
          setClientSecretLoading(true);
          setLoading(true);
          const getResponse = (response) => {
            if (response.result === "SUCCESS") {
              setClientSecretLoading(false);
              setLoading(false)
              setSessionData(response?.data)
            } else {
              setLoading(false)
              setClientSecretLoading(false);
              const error = response.error;
              showToast(error.severity, error.errorMsg);
            }
          };
  
          const getCartObj = { ...cartData };
          delete getCartObj.discountedPrice;
          delete getCartObj.dataAmountForDisplay;
          delete getCartObj.promoCodePrice;
          getCartObj.promoCode = data?.promoCode;

          const dataPlans = [getCartObj];
  
          const customerDetails = {
            firstName: userInfo.firstName ? userInfo.firstName : values.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email ? userInfo.email : values.email,
            phone: getPhoneObj('', isAuth, userData, '')   
          };
          const deviceInfo = { device: globalConfig.deviceInfo };
          const paymentData = {
            contactInfo: customerDetails,
            orderSummary: {
              dataPlans: dataPlans,
              totalOrderAmount: orderAmount,
            },
            paymentGateway: "Stripe",
            paymentMethodId: uuidv4(),
            randomId: randomId ? randomId : uuidv4(),
            iccid: iccid,
            isEsimCompatible: globalConfig.isEsimCompatible,
            deviceInfo: deviceInfo,
          };
          getCheckoutSessionAPI(paymentData, headers, dispatch, getResponse);
        },
        [cartData, dispatch, globalConfig.deviceInfo, globalConfig.isEsimCompatible, headers, iccid, isAuth, orderAmount, randomId, showToast, userData, userInfo]
      );

      const changePromoCode = (val) => {
        setPromoCode(val);
        setPromoCodeError('');
    };

    const displayPromoCodeData = useCallback((response, promoCode, affiliatePromocode) => {
        const getCartObj = response === "remove" ? { ...promoCode } : { ...cartData };
        setInitialCartData({ ...getCartObj });
      if (response === "remove") {
            delete getCartObj.promoCode;
          }
        if (promoCode === '' && !affiliatePromocode) {
            changePromoCode('');
            delete getCartObj.discountedPrice;
            delete getCartObj.promoCode;
            delete getCartObj.promoCodePrice;
            delete getCartObj.discountValue;
        } else if (response?.discountedPrice !== response?.bundlePrice) {
            getCartObj.discountValue = response.discountValue;
            getCartObj.discountedPrice = +response.discountedPrice;
            getCartObj.promoCodePrice = +response.promoCodePrice;
            getCartObj.promoCode = promoCode;
        }
        if(userInfo?.email) {
          v2PayHandler(userInfo, {promoCode : response === "remove" ? "" : promoCode})
        }
  
        setLoading(false)
        dispatch(cartFilterActions.setCatalogCart(getCartObj));
    }, [cartData, dispatch, userInfo, v2PayHandler]);

    const removePromoCode = () => {
        changePromoCode('');
        displayPromoCodeData('remove', initialCartData);
    }
    const validatePromocodeHandler = useCallback(async (affiliatePromocode) => {
        if(affiliatePromocode){
          setAffiliatePromoCodeCall(affiliatePromocode)
        }
        if (promoCode === '' && !affiliatePromocode) {
            setPromoCodeError(validations.VLD0016);
            return null
        } else {
            setLoading(true)
            let requestObj = {
                bundleName: cartData.name,
                promoCode: promoCode,
                randomId: randomId ? randomId : uuidv4(),
                iccid: iccid
            }
    
            const getResponse = (response) => {
                if (response.result === "SUCCESS") {
                    dispatch(cartFilterActions.setRandomIdForPayment(requestObj.randomId))
                    displayPromoCodeData(response.data, promoCode, affiliatePromocode);
                } else {
                    setLoading(false)
                    const error = response.error;
                    const message = showMessage(error.errorMsg); 
                    !affiliatePromocode && setPromoCodeError(message)
                }
            }
            validatePromoCodeAPI(requestObj, headers, dispatch, getResponse)
        }
        
    }, [cartData.name, dispatch, displayPromoCodeData, headers, iccid, promoCode, randomId, showMessage, validations.VLD0016])

    useEffect(() => {
        if(isAuth && !affiliatePromoCodeCall && !sessionData?.sessionClientSecret) {
          if(cartData?.price > 0.5) {
            validatePromocodeHandler(true);
          } else {
            v2PayHandler()
          }
        }
    }, [affiliatePromoCodeCall, cartData?.price, isAuth, sessionData, v2PayHandler, validatePromocodeHandler])

    useEffect(() => {
        const getCartObj = { ...cartData };
        if ('promoCode' in getCartObj) {
            changePromoCode(getCartObj.promoCode);
        }
    }, [cartData])

    const confirmOrderHandler = (values) => {
        setLoading(true)
        const getPaymentData = (response) => {
            if (response.result === "SUCCESS") {
                setLoading(false);
                setUserFormVisible(false);
                setPaymentResponse(response.data);
                const paymentResponseObj = response.data;
                paymentProps(paymentResponseObj, loading);
            } else {
                setLoading(false)
                const error = response.error;
                showToast(error.severity, error.errorMsg);
            }
        }

        const getCartObj = { ...cartData };
        //delete getCartObj.discountedPercentage;
        delete getCartObj.discountedPrice;
        delete getCartObj.dataAmountForDisplay;
        delete getCartObj.promoCodePrice;

        const dataPlans = [getCartObj];

        const customerDetails = {
            firstName: userInfo.firstName ? userInfo.firstName : values.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email ? userInfo.email : values.email,
            phone: getPhoneObj('', isAuth, userData, '')
        }
        const deviceInfo = { device: globalConfig.deviceInfo };
        const paymentData = {
            contactInfo: customerDetails,
            orderSummary: {
                dataPlans: dataPlans,
                totalOrderAmount: orderAmount
            },
            paymentGateway: "none",
            paymentMethodId: uuidv4(),
            randomId: randomId ? randomId : uuidv4(),
            iccid: iccid,
            isEsimCompatible: globalConfig.isEsimCompatible,
            deviceInfo: deviceInfo
        }

        discountPayment(paymentData, headers, dispatch, getPaymentData);
    }

    const userFormValidationSchema = () => {

        return Yup.object().shape({
            firstName: Yup.string().trim()
                .required(validations.VLD0007)
                .min(3, validations.VLD0008)
                .max(20, validations.VLD0008),
            email: Yup.string().trim()
                .email(validations.VLD0006)
                .required(validations.VLD0005),
        })
    }
    const formik = useFormik({
        initialValues:{
            firstName:"",
            email : ""
        },
        validationSchema: userFormValidationSchema,
        onSubmit: confirmOrderHandler
    })

    const renderPayment = (type) => {
        setPaymentType(type);
        setPaymentVisible(true)
    }

    const renderActiveClassNameForPayment = (type) => {

        return (paymentType === type && compatibilityCheck && "payment-active" )
    }

    const closeBillingAddressHandler = () => {
        setUserFormVisible(false);
        formik.resetForm();
    }

    const navigateSuccessScreen = (res) => {
        if (res && res?.paymentResponse) {
            setUserFormVisible(false);
            dispatch(paymentActions.setPaymentResponse(res))
            navigate("/payment-success", {state: {payRes: {...res}} });
        }
    }
    const stripeFormik = useFormik({
        initialValues:{
            firstName: userData?.firstName ?? "",
            email : userData?.email ?? ""
        },
        validationSchema: userFormValidationSchema,
        onSubmit:  (values) => {
          setUserInfo(values);
          v2PayHandler(values, {promoCode})
        }
    })

    return (
      <>
        <div className="grid grid-nogutter mb-5 border-bottom-1 border-300 pb-4 align-items-center">
          <div className="col-12 text-center">
            <h2 className="payment-heading">{labels.LBL0090}</h2>
          </div>
        </div>

        <PR.Dialog
          blockScroll={true}
          draggable={false}
          header={labels.LBL0060}
          visible={!isLoggedIn && userFormVisible}
          style={{ width: "450px" }}
          className="promocode-modal-dialog"
          onHide={closeBillingAddressHandler}
          resizable={false}
        >
          <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
            <div className="col-12 md:col-12">
              <span className="p-input-icon-left w-12">
                <i className="pi pi-user" />
                <PR.InputText
                  id="firstName"
                  name="firstName"
                  placeholder={labels.LBL0001}
                  className="w-12"
                  keyfilter={"alphanum"}
                  value={formik.values.firstName}
                  onChange={(e) => handleFirstNameChange(e, formik)}
                  onBlur={formik.handleBlur}
                />
              </span>
              {formik.errors.firstName && formik.touched.firstName ? (
                <div className="error-message">{formik.errors.firstName}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 md:col-12">
              <span className="p-input-icon-left w-12">
                <i className="pi pi-envelope" />
                <PR.InputText
                  id="email"
                  name="email"
                  placeholder={labels.LBL0010}
                  className="w-12"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </span>
              {formik.errors.email && formik.touched.email ? (
                <div className="error-message">{formik.errors.email}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 md:col-12 text-center">
              <PR.Button
                label={labels.LBL0091}
                className="confirm_order_button"
                type="submit"
                disabled={!formik.isValid || loading}
              />
            </div>
          </form>
        </PR.Dialog>

        <div className="grid grid-nogutter payment-options">
          <div className="col-12">
            <div className="left">
              <DeviceCompatibilty
                setCompatibilityCheck={setCompatibilityCheck}
              />
            </div>
          </div>
          <div className="col-12 lg:col-7 md:col-7 border-right-1 border-300">
            <div className="left">
              <div className={`${!compatibilityCheck && "disablePayments"}`}>
                <div
                  className={`${
                    cartData.discountedPrice === 0 && "disablePayments"
                  }`}
                >
                  <p
                    onClick={() => renderPayment("PAYPAL")}
                    className={
                      "payment-button " +
                      renderActiveClassNameForPayment("PAYPAL")
                    }
                  >
                    <PR.Image
                      src={paypalIcon}
                      alt="Paypal"
                      className="payment-img"
                    />
                    <span className="payment-text">{labels.LBL0092}</span>
                  </p>
                  <p onClick={() => renderPayment('CARD')} className={"payment-button " + renderActiveClassNameForPayment("CARD")}>
                    <PR.Image src={debitCardIcon} alt="Debit/Credit Card" className="payment-img" />
                    <span className="payment-text">{labels.LBL0093}</span>
                    <PR.Image src={poweredByStrpeIcon} alt="Powered by stripe" className="stripe-img" />
                  </p>
                  {paymentType === "CARD" && (
                    <div className="debit-card-section mb-4">
                      {paymentType === "CARD" &&
                        cartData.discountedPrice !== 0 && (
                          <>
                            <form
                              onSubmit={stripeFormik.handleSubmit}
                              autoComplete={"off"}
                            >
                              <div className="col-12 md:col-12">
                                <span className="p-input-icon-left w-12">
                                  <i className="pi pi-user" />
                                  <PR.InputText
                                    id="firstName"
                                    name="firstName"
                                    placeholder={labels.LBL0001}
                                    className="w-12"
                                    keyfilter={"alphanum"}
                                    value={stripeFormik.values.firstName}
                                    onChange={(e) =>
                                      handleFirstNameChange(e, stripeFormik)
                                    }
                                    onBlur={stripeFormik.handleBlur}
                                    disabled={
                                      isLoggedIn ||
                                      sessionData?.sessionClientSecret
                                    }
                                  />
                                </span>
                                {stripeFormik.errors.firstName &&
                                stripeFormik.touched.firstName ? (
                                  <div className="error-message mt-3">
                                    {stripeFormik.errors.firstName}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-12 md:col-12">
                                <span className="p-input-icon-left w-12">
                                  <i className="pi pi-envelope" />
                                  <PR.InputText
                                    id="email"
                                    name="email"
                                    placeholder={labels.LBL0010}
                                    className="w-12"
                                    value={stripeFormik.values.email}
                                    onChange={stripeFormik.handleChange}
                                    onBlur={stripeFormik.handleBlur}
                                    disabled={
                                      isLoggedIn ||
                                      sessionData?.sessionClientSecret
                                    }
                                  />
                                </span>
                                {stripeFormik.errors.email &&
                                stripeFormik.touched.email ? (
                                  <div className="error-message mt-3">
                                    {stripeFormik.errors.email}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {!sessionData?.sessionClientSecret && (
                                <div className="col-12 md:col-12 text-center">
                                  <PR.Button
                                    label={labels.LBL0152}
                                    className="confirm_order_button pay-button continue-button"
                                    type="submit"
                                    disabled={!stripeFormik.isValid || loading}
                                  />
                                </div>
                              )}
                            </form>
                            {cartData.discountedPrice !== 0 && (
                              <>
                                {!clientSecretLoading ? (
                                  sessionData?.sessionClientSecret && (
                                    <CustomCheckoutProvider
                                      stripe={stripePublicKeyV2}
                                      options={{
                                        clientSecret:
                                          sessionData?.sessionClientSecret,
                                        elementsOptions,
                                      }}
                                    >
                                      <CardPayment
                                        applyPromoCode={applyPromoCode}
                                        setApplyPromoCode={setApplyPromoCode}
                                        userInfo={stripeFormik.values}
                                      />
                                    </CustomCheckoutProvider>
                                  )
                                ) : (
                                  <div className="flex align-items-center justify-content-center gap-2 pt-3 pb-2">
                                    <i className="pi pi-spin pi-spinner"></i>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                    </div>
                  )}
                </div>
                {cartData.discountedPrice === 0 ? (
                  <PR.Button
                    label="Confirm Order"
                    className="order-btn"
                    onClick={() =>
                      isLoggedIn
                        ? confirmOrderHandler()
                        : setUserFormVisible(true)
                    }
                  />
                ) : paymentVisible && paymentType !== "CARD" ? (
                  <PR.Dialog
                    visible={payVisible}
                    onHide={() => setPaymentType("")}
                    draggable={false}
                    blockScroll={true}
                    resizable={false}
                    className={"paypal-dialog " + (loading && "hide-payment")}
                  >
                    {paymentType === "PAYPAL" && (
                      <PaypalPayment
                        paymentProps={paymentProps}
                        setPaymentType={setPaymentType}
                        affiliatePath={affiliatePath}
                      />
                    )}
                    {/* {googlePayDisplay && paymentType === "GPAY" && (
                      <Elements stripe={stripePublicKey}>
                        <GooglePayPayment
                          paymentProps={paymentProps}
                          setPaymentType={setPaymentType}
                          affiliatePath={affiliatePath}
                        />
                      </Elements>
                    )}
                    {applePayDisplay && paymentType === "APPLEPAY" && (
                      <Elements stripe={stripePublicKey}>
                        <ApplePayPayment
                          paymentProps={paymentProps}
                          setPaymentType={setPaymentType}
                          affiliatePath={affiliatePath}
                        />
                      </Elements>
                    )} */}
                    {loading && (
                      <div className="loading">
                        <i className="pi pi-spin pi-spinner"></i>
                      </div>
                    )}
                  </PR.Dialog>
                ): null}
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-5 md:col-5">
            <div className="right">
              <h2>{labels.LBL0096}</h2>
              <div className="bundle-list">
                <div className="grid">
                  <div className="col-3">
                    <PR.Image src={esimIcon} alt="esimIcon" />
                  </div>
                  <div className="col-9 flex">
                    <h4>{cartData.description}</h4>
                    {/* <span className="trash" onClick={removeCartHandler}><i className="pi pi-trash"></i></span> */}
                  </div>
                  <div className="col-12 bundle-info">
                    <div className="mt-2">
                      <ul className="flex align-items-center justify-content-between">
                        <li>{labels.LBL0024}:</li>
                        <li>
                          {setDataAmount(cartData, labels)}{" "}
                          {unlimitedPlanUsageAsterisk(
                            cartData?.dataAmountForDisplay,
                            labels
                          )}
                        </li>
                      </ul>
                      <ul className="flex align-items-center justify-content-between">
                        <li>{labels.LBL0025}:</li>
                        <li>
                          {cartData.duration} {labels.LBL0018}
                        </li>
                      </ul>
                      {cartData.speed && (
                        <ul className="flex align-items-center justify-content-between">
                          <li>{labels.LBL0026}:</li>
                          <li>{cartData.speed.join("/")}</li>
                        </ul>
                      )}
                      <ul className="flex align-items-center justify-content-between">
                        <li>{labels.LBL0097}:</li>
                        <li>{renderPrice(cartData.price)} </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {cartData.promoCode ? (
                  <div className="mt-5">
                    <div className="code-apply grid align-items-center justify-content-between">
                      <span>
                        {labels.LBL0098}:{" "}
                        <b className="font-italic">{promoCode}</b>{" "}
                      </span>
                      <i className="pi pi-times" onClick={removePromoCode}></i>
                    </div>
                  </div>
                ) : (
                  <div className="grid mt-5 align-items-center justify-content-between">
                    <div className="col-8 relative">
                      <PR.InputText
                        placeholder={labels.LBL0099}
                        maxLength="20"
                        value={promoCode}
                        onChange={(e) => changePromoCode(e.target.value)}
                      />
                    </div>
                    <div className="col-4">
                      <PR.Button
                        type="submit"
                        label="Apply"
                        className="apply-button"
                        onClick={() => validatePromocodeHandler(false)}
                      />
                    </div>
                    {promoCodeError && (
                      <div className="err-msg" style={{ fontSize: "12px" }}>
                        {promoCodeError}
                      </div>
                    )}
                  </div>
                )}

                <div className="grid total-price mt-0">
                  <div className="col-12">
                    {cartData.promoCodePrice > 0 && (
                      <>
                        <ul>
                          <li>{labels.LBL0100}</li>
                          <li>{renderPrice(cartData.price)}</li>
                        </ul>
                        <ul>
                          <li>
                            {labels.LBL0101} (
                            {cartData?.discountValue ? (
                              <span style={{ color: "green" }}>
                                {cartData?.discountValue}
                              </span>
                            ) : (
                              "-"
                            )}
                            )
                          </li>
                          <li>{renderPrice(cartData.promoCodePrice)}</li>
                        </ul>
                      </>
                    )}
                    <hr />
                    <ul className="mt-3">
                      <li>
                        <b>{labels.LBL0102}</b>
                      </li>
                      <li>
                        <b>{renderPrice(totalOrderAmount)}</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="loading-div">
            <i className="pi pi-spin pi-spinner flex"></i>
          </div>
        )}
      </>
    );
};

export default MakePayment;